import { t } from '@ping/helpers';
import { Button } from '@ping/uikit';
import { useRouter } from 'next/router';

import styles from './style.module.scss';

import type { NextPage } from 'next';

const NotFoundPage: NextPage = () => {
  const router = useRouter();
  const handleGoBack = () => {
    router.back();
  };

  return (
    <div className={styles['notfound-wrapper']}>
      <h3 className={styles['error-number']}>404</h3>
      <h5 className={styles['error-message']}>{t('Page not found')}</h5>
      <p className={styles['error-description']}>
        {t('We’re sorry the page that you requested could not be found. Please go back to the homepage')}
      </p>
      <Button type='primary' className={styles['goback-button']} onClick={handleGoBack} uppercase>
        {t('go back')}
      </Button>
    </div>
  );
};

export default NotFoundPage;
